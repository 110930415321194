<template>
  <section class="w-full lg:container lg:mx-auto" ref="showList">
    <!--Show List-->
    <div v-if="shows" class="w-full flex flex-col">
      <ShowCard
        v-for="show in shows"
        :key="`show-${show.id}-${show.date}`"
        :id="`show-${show.id}-${show.date}`"
        class="border-b last:border-b-0 border-linear-g-primary py-12 px-4 md:px-0 md:py-16 md:gap-10"
        :show="show"
        :ui="ui"
        @performance:click="saveScrollPosition"
        @showLink:click="onShowDetailLinkClick"
        @readMore:click="onReadMore"
      />
    </div>
    <div v-if="loading" class="mt-4 flex justify-center">
      <Spinner :display="loading" />
    </div>

    <!-- Read More Dialog -->
    <div
      v-if="readMoreDialog && currentShow"
      @keyup.esc="readMoreDialogClose"
      ref="readMoreDialog"
      tabindex="0"
      class="read-more-dialog fixed top-0 left-0 w-full h-full flex items-center justify-center md:p-16 lg:p-32"
    >
      <!--Background overlay-->
      <div class="bg-black/80 absolute left-0 top-0 w-full h-full" @click="readMoreDialogClose" />

      <!--Dialog-->
      <div
        class="relative w-full max-h-full overflow-y-auto bg-body-primary z-10 lg:container lg:mx-auto"
      >
        <!--Close button-->
        <button
          :id="`read-more-dialog-close-${currentShow.id}-${currentShow.date}`"
          class="w-10 h-10 rounded flex items-center justify-center top-0 bg-body-primary absolute right-0 z-20"
          @click="readMoreDialogClose"
          aria-label="Close Show Description"
        >
          <xCloseGuest />
        </button>

        <!--Show card-->
        <ShowCard
          class="p-4 md:gap-10 md:p-8"
          :show="currentShow"
          :id="`show-${currentShow.id}-${currentShow.date}-in-dialog`"
          :ui="ui"
          descriptionDisplayType="long"
          keepReadMoreText
          @performance:click="saveScrollPosition"
          @readMore:click="readMoreDialogClose"
        >
          <template #readMoreText>Read Less</template>
        </ShowCard>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import moment from 'moment'
import { mapStores } from 'pinia'
import { defineComponent } from 'vue'

import xCloseGuest from '@/assets/xCloseGuest.svg?component'
import Spinner from '@/common/components/atoms/Spinner.vue'
import { StorePerformance } from '@/models'
import ShowCard from '@/modules/guestUi/components/organisms/ShowCard.vue'
import { DisplayShow } from '@/modules/guestUi/interfaces/shows'
import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'
import { blankError } from '@/utils/errors'
import { sortBy } from '@/utils/utils'

import { PerformanceId } from '@generated/types'

export default defineComponent({
  name: 'SetShow',
  components: {
    ShowCard,
    Spinner,
    xCloseGuest,
  },
  data() {
    return {
      onScroll: () => {},
      scrollY: '',
      loading: false,
      total: 0,
      currentPage: 1,
      currentShow: null as DisplayShow | null,
      pageSize: 5,
      readMoreDialog: false,
      ui: {
        error: blankError(),
      },
    }
  },
  props: {
    performanceId: {
      type: Number as () => PerformanceId | undefined,
      required: false,
      default: undefined,
    },
  },
  beforeMount() {
    if (window) {
      this.onScroll = () => {
        if (!this.$refs.showList || this.loading || this.currentPage * this.pageSize >= this.total)
          return

        const scrollY = window.scrollY,
          pageHeight = window.innerHeight
        const totalHeight = (this.$refs.showList as Element).clientHeight

        if (totalHeight - scrollY <= pageHeight + 50) {
          this.loading = true
          this.currentPage++

          this.checkoutStore
            .getPerformances({ page: this.currentPage, booking: 'true' })
            .then(() => {
              this.$nextTick(() => {
                this.loading = false
                this.onScroll()
              })
            })
        }
      }

      window.addEventListener('scroll', this.onScroll)
      this.$nextTick(() => this.onScroll())
    }
  },

  beforeUnmount() {
    if (window) {
      window.removeEventListener('scroll', this.onScroll)
    }
  },

  async created() {
    this.settingStore.ssrTitle = `Shows - ${this.settingStore.venue_name}`
    this.settingStore.ssrDescription = `Purchase tickets for upcoming shows at ${this.settingStore.venue_name}.`
  },

  watch: {
    async $route() {
      await this.init()
    },
    readMoreDialog(value) {
      if (value) {
        document.body.style.setProperty('overflow', 'hidden')
        setTimeout(() => {
          const readMoreDialog = this.$refs.readMoreDialog as unknown as HTMLDivElement
          readMoreDialog.focus()
        }, 0)
      } else {
        document.body.style.setProperty('overflow', 'initial')
      }
    },
  },

  computed: {
    ...mapStores(useSettingStore, useCheckoutStore),

    inviteCode(): string | undefined {
      return this.$route.query.invite as string | undefined
    },

    performances(): StorePerformance[] {
      if (this.performanceId) return this.checkoutStore.performances

      return this.checkoutStore.performances.filter(p =>
        p.publish_datetime === null ? true : moment(p.publish_datetime).isSameOrBefore(moment()),
      )
    },

    shows(): DisplayShow[] {
      const shows: DisplayShow[] = []

      const performancesToConsider = this.performanceId
        ? this.performances.filter(p => p.id === this.performanceId)
        : this.performances
      for (const performance of performancesToConsider) {
        if (!this.checkoutStore.isPerformanceVisibleOnTheList(performance)) continue

        const show = shows.find(
          show => show.date == performance.date && show.id == performance.show_id,
        )

        if (show) {
          show.performances.push(performance)
          sortBy(show.performances, 'datetime')
        } else {
          const desc = performance.show?.description || ''
          shows.push({
            ...performance.show,
            id: performance.show_id,
            date: performance.date,
            datetime: performance.datetime,
            dateStr: this.settingStore.mediumDate(performance.datetime),
            description: desc,
            position: performance.show?.position || [],
            performances: [performance],
            forcePurchaseForPerformanceId: this.performanceId,
          })
        }
      }
      return sortBy(shows, 'datetime')
    },
  },

  async mounted() {
    await this.init()
    this.checkoutStore.performance = null
    this.scrollToShow()
    this.checkoutStore.setInviteCode(this.inviteCode)
  },

  methods: {
    async init() {
      if (this.inviteCode && this.performanceId) {
        const { data: performance } = await this.checkoutStore.getPerformanceWithInvite(
          this.performanceId,
          this.inviteCode,
        )
        this.checkoutStore.storePerformances([performance])
        return
      }

      const response = await this.checkoutStore.getPerformances({ booking: 'true' })

      this.currentPage = 1
      this.total = response.total
      this.pageSize = response.pageSize
    },

    onReadMore(show: DisplayShow) {
      this.readMoreDialog = true
      this.currentShow = show
      this.saveScrollPosition(show.performances[0])
    },

    readMoreDialogClose() {
      // Focus on the current show card, accessibility
      const currentShowCard = document
        .getElementById(`show-${this.currentShow?.id}-${this.currentShow?.date}`)!
        .querySelector('.performance-btn') as HTMLButtonElement
      currentShowCard.focus()

      this.readMoreDialog = false
      this.currentShow = null
    },

    onShowDetailLinkClick(show: DisplayShow) {
      document.body.style.setProperty('overflow', 'initial')
      const performance = show.performances[0]
      this.saveScrollPosition(performance)
    },

    saveScrollPosition(performance: StorePerformance) {
      document.body.style.setProperty('overflow', 'initial')
      const performanceContainerElement = document
        .getElementById(`performance-${performance.id}`)
        ?.closest('.performances')
      if (!performanceContainerElement) return
      const { top } = performanceContainerElement.getBoundingClientRect()
      const topFromWindow = top + window.scrollY
      this.checkoutStore.setScrollPositionFromSetShow(topFromWindow)
    },

    scrollToShow() {
      if (this.checkoutStore.scrollPositionFromSetShow) {
        window.scrollTo({ top: this.checkoutStore.scrollPositionFromSetShow - 100 })
        this.checkoutStore.setScrollPositionFromSetShow(null)
      }
    },
  },
})
</script>
