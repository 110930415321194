import { useSettingStore } from '@/stores/setting'
import { useShowStore } from '@/stores/show'
import { cleanString, sortBy } from '@/utils/utils'

import {
  AddOnRead,
  ArrangementId,
  ArrangementRead,
  FloorPlanRead,
  FloorPlanTableRead,
  GuestRead,
  PerformanceAddOnRead,
  PerformanceId,
  PerformanceListRead,
  PerformanceRead,
  PerformanceSeatingTimeId,
  PerformanceTableRead,
  PromotionCodeRead,
  ReservationAddOnRead,
  ReservationId,
  ReservationRead,
  ReservationStatusType,
  ShowId,
  ShowRead,
  ShowSrcsetRead,
  ShowTimeId,
  ShowTimeRead,
  TypedId,
} from '@generated/types'

export interface HasId<FlavorT> {
  id: TypedId<FlavorT>
}

export interface FormSeatingTime {
  id?: number
  time24: string
  maximum_guests: number
  deletable?: boolean
}

export interface ShowImageCrop {
  desktopX: number
  desktopY: number
  mobileX: number
  mobileY: number
}

export function position2crop(position: number[] | null | undefined): ShowImageCrop {
  if (!position) {
    return { desktopX: 0.5, desktopY: 0.5, mobileX: 0.5, mobileY: 0.5 }
  }
  return {
    desktopX: position[0] || 0.5,
    desktopY: position[1] || 0.5,
    mobileX: position[2] || 0.5,
    mobileY: position[3] || 0.5,
  }
}

export function crop2position(crop: ShowImageCrop) {
  return [crop.desktopX, crop.desktopY, crop.mobileX, crop.mobileY]
}

export interface StoreAddOn extends AddOnRead {}

export interface ParsedSeatingTime {
  datetime: string
  time: string
  time24: string
  reserved_guests?: number
  maximum_guests: number
  sold: boolean
}

export interface StorePerformanceSeatingTime extends ParsedSeatingTime {
  id: PerformanceSeatingTimeId
}

export interface StoreFloorPlan {
  id: ArrangementId
  name: string
  tables: FloorPlanTableRead[]
  is_primary?: boolean
  pricing: number[]
  pricingStr: string
}

export interface StoreShowTime {
  id: ShowTimeId
  name: string
  display: string
  seating_times: ParsedSeatingTime[]
  time: string
  time24: string
}

export interface StoreShow {
  id: ShowId
  name: string
  image: string
  srcset: ShowSrcsetRead
  price_per_person?: number[] | null
  position?: number[]
  crop: ShowImageCrop
  description?: string
}

export interface StorePerformance {
  id: PerformanceId
  datetime: string
  show_id: number
  show: StoreShow
  seating_times?: StorePerformanceSeatingTime[]
  tables?: PerformanceTableRead[]
  sold: boolean
  date: string
  time: string
  add_ons: PerformanceAddOnRead[]
  publish_datetime?: string
}

export interface StorePromotionCode extends PromotionCodeRead {}

export interface StoreReservation {
  id: ReservationId
  user?: any | null
  contact: GuestRead | null
  seating_time: any
  performance_id: PerformanceId
  tables: any[]
  number_of_guests: number
  service_fee: number
  guest_notes: string
  internal_notes: string
  amount: number
  add_on_amount?: number
  add_ons: ReservationAddOnRead[]
  tax: number
  discounted: number
  status: ReservationStatusType
  promotion_code?: PromotionCodeRead
}

interface RawSeatingTime {
  time: string
  maximum_guests: number
  reserved_guests?: number
}
interface RawPerformanceSeatingTime extends RawSeatingTime {
  id: PerformanceSeatingTimeId
}

export function parseAddOn(addOn: AddOnRead): StoreAddOn {
  return { ...addOn, price: +addOn.price, tax_rate: +addOn.tax_rate }
}

export function parseSeatingTime(seating_time: RawSeatingTime): ParsedSeatingTime {
  return {
    ...seating_time,
    datetime: seating_time.time,
    time: useSettingStore().timeStr(seating_time.time),
    time24: useSettingStore().time24Str(seating_time.time),
    sold: seating_time.maximum_guests <= (seating_time?.reserved_guests || 0),
  }
}

export function parsePerformanceSeatingTime(
  seating_time: RawPerformanceSeatingTime,
): StorePerformanceSeatingTime {
  return {
    ...parseSeatingTime(seating_time),
    id: seating_time.id,
  }
}

export function parseSeatingTimeList(
  seating_times: RawSeatingTime[] | undefined,
): ParsedSeatingTime[] | undefined {
  if (seating_times) {
    return sortBy(seating_times.map(parseSeatingTime), 'time')
  }
}

export function parsePerformanceSeatingTimeList(
  seating_times: RawPerformanceSeatingTime[] | undefined,
): StorePerformanceSeatingTime[] | undefined {
  if (seating_times) {
    return sortBy(seating_times.map(parsePerformanceSeatingTime), 'time')
  }
}

export function parsePerformance(
  performance: PerformanceListRead | PerformanceRead,
): StorePerformance {
  const settingStore = useSettingStore()
  let show: StoreShow
  if (performance.show) {
    show = parseShow(performance.show)
  } else {
    show = useShowStore().showById(performance.show_id)
  }

  const result = {
    ...performance,
    add_ons: performance['add_ons'] || [],
    sold: performance?.sold || false,
    show_id: performance.show_id || 0,
    date: settingStore.dateIsoStr(performance.datetime),
    time: settingStore.time24Str(performance.datetime),
    seating_times: parsePerformanceSeatingTimeList(performance['seating_times']),
    publish_datetime: performance.publish_datetime || undefined,
    show,
  }

  return result
}

export function parseFloorPlan(arrangement: ArrangementRead | FloorPlanRead): StoreFloorPlan {
  const pricing = [
    ...new Set((arrangement.tables || []).map(table => table.default_price_per_person || 0)),
  ].sort()
  return {
    ...arrangement,
    tables: arrangement.tables || [],
    pricing: pricing,
    pricingStr: pricing.map(price => `$${price}`).join(' / '),
  }
}

export function parseShow(show: ShowRead): StoreShow {
  return { ...show, crop: position2crop(show.position) }
}

export function parseShowTime(showtime: ShowTimeRead): StoreShowTime {
  const settingStore = useSettingStore()
  const time = settingStore.timeStr(showtime.time)
  return {
    ...showtime,
    name: showtime.name || '',
    display: `${time} - ${showtime.name}`,
    seating_times: parseSeatingTimeList(showtime.seating_times) || [],
    time24: settingStore.time24Str(showtime.time),
  }
}

export function parseReservation(reservation: ReservationRead): StoreReservation {
  return reservation
}

export function isWalkIn(reservation: ReservationRead): boolean {
  return (
    !reservation.contact ||
    cleanString(reservation.contact.name) === cleanString('Walk In') ||
    reservation.contact.email.startsWith('anonymous+walkin')
  )
}

export function isValidUrl(string: string) {
  try {
    new URL(string)
    return true
  } catch (err) {
    return false
  }
}
