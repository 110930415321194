import * as _ from 'lodash-es'
import { defineStore } from 'pinia'

import { TableWithReservationAndUpdates } from '@/common/interfaces/TableBulkEditing'
import { isWalkIn } from '@/models'
import {
  PerformanceTableEvent,
  TABLE_UPDATE_STATUS,
} from '@/modules/performance/interfaces/PerformanceTableInterfaces'
import { usePerformanceStore } from '@/modules/performance/stores/PerformanceStore'
import { useReservationStore } from '@/modules/reservation/stores/ReservationStore'
import { useSettingStore } from '@/stores/setting'
import { useUserStore } from '@/stores/user'
import { sum } from '@/utils/utils'

import {
  GuestRead,
  PerformanceId,
  PerformanceReservationsRead,
  PerformanceTableId,
  PerformanceTableRead,
  ReservationDetailedRead,
  ReservationId,
  ReservationRead,
  ReservationStatus,
} from '@generated/types'

export interface PerformanceReservationState {
  selectedPerformanceId: PerformanceId
  selectedPerformance: PerformanceReservationsRead | null
  loadingState: PerformanceLoadingStatus
  selectedTableIds: PerformanceTableId[]
  lowOpacityTableIds: PerformanceTableId[]
  filledTableIds: PerformanceTableId[]
  selectedReservation: ReservationDetailedRead | null
  loadingReservationState: ReservationLoadingStatus
}
type PerformanceLoadingStatus =
  (typeof PERFORMANCE_LOADING_STATE)[keyof typeof PERFORMANCE_LOADING_STATE]
type ReservationLoadingStatus =
  (typeof RESERVATION_LOADING_STATE)[keyof typeof RESERVATION_LOADING_STATE]
export type PerformanceTableWithReservations = TableWithReservationAndUpdates<PerformanceTableRead>
export const PERFORMANCE_LOADING_STATE = {
  NONE: 'none',
  LOADING: 'loading',
}
export const RESERVATION_LOADING_STATE = _.cloneDeep(PERFORMANCE_LOADING_STATE)
export const PERFORMANCE_PARAMS = { EMPTY: 0 }

export const usePerformanceReservationStore = defineStore({
  id: 'performanceReservation',
  state: (): PerformanceReservationState => ({
    selectedPerformanceId: PERFORMANCE_PARAMS.EMPTY,
    selectedPerformance: null,
    loadingState: PERFORMANCE_LOADING_STATE.NONE,
    selectedTableIds: [],
    lowOpacityTableIds: [],
    filledTableIds: [],
    selectedReservation: null,
    loadingReservationState: RESERVATION_LOADING_STATE.NONE,
  }),
  actions: {
    async backgroundRefresh(): Promise<void> {
      if (!this.selectedPerformance) return
      const performanceStore = usePerformanceStore()
      this.setSelectedPerformance(
        await performanceStore.getPerformanceReservations(this.selectedPerformance.id),
      )
    },
    setSelectedPerformance(performance: Partial<PerformanceReservationsRead>): void {
      this.selectedPerformance = _.cloneDeep({
        ...this.selectedPerformance,
        ...performance,
      }) as PerformanceReservationsRead
      if (!this.selectedPerformance) return

      const settingStore = useSettingStore()
      if (
        this.selectedPerformance.on_sale_datetime &&
        settingStore.parseMoment(this.selectedPerformance.on_sale_datetime).isBefore('2021-01-01')
      ) {
        this.selectedPerformance.on_sale_datetime = null
      }
      this.selectedPerformance.tables.forEach(table => {
        if (!table.type_id) table.type_id = settingStore.table_types[0].id
      })
      // Sort seating times by time
      this.selectedPerformance.seating_times = _.sortBy(
        this.selectedPerformance.seating_times,
        'time',
      )
      // This is because we get numbers like "8.2500" from the API, and when we put the price in the form,
      // it is parsed to "8.25", and it shows the user the interface to save changes as if the price was changed.
      this.selectedPerformance.add_ons.forEach(addOn => {
        addOn.price = Number(addOn.price).toFixed(2)
        addOn.tax_rate = Number(addOn.tax_rate).toFixed(2)
      })
      this.selectedPerformance.service_fee =
        this.selectedPerformance.service_fee === null
          ? null
          : Number(this.selectedPerformance.service_fee)
      this.selectedPerformance.service_percentage =
        this.selectedPerformance.service_percentage === null
          ? null
          : Number(this.selectedPerformance.service_percentage)
    },
    async loadSelectedPerformance(performanceId: PerformanceId): Promise<void> {
      try {
        const performanceStore = usePerformanceStore()
        this.removeSelectedPerformance()
        this.removeSelectedReservation()
        if (performanceId === PERFORMANCE_PARAMS.EMPTY) return
        this.selectedPerformanceId = performanceId
        this.loadingState = PERFORMANCE_LOADING_STATE.LOADING
        this.setSelectedPerformance(
          await performanceStore.getPerformanceReservations(performanceId),
        )
      } finally {
        this.loadingState = PERFORMANCE_LOADING_STATE.NONE
      }
    },
    isReservationAnonymous(reservation: ReservationDetailedRead | ReservationRead): boolean {
      return reservation.contact === null && reservation.seating_time_id === null
    },
    removeSelectedPerformance(): void {
      this.selectedPerformance = null
    },
    setSelectedReservation(reservation: ReservationDetailedRead): void {
      this.selectedReservation = _.cloneDeep({ ...this.selectedReservation, ...reservation })
      this.setSelectedTableIds(this.selectedReservation.tables)
    },
    async loadSelectedReservation(reservationId: ReservationId): Promise<void> {
      try {
        const reservationStore = useReservationStore()
        this.loadingReservationState = RESERVATION_LOADING_STATE.LOADING
        this.selectedReservation = (await reservationStore.getReservation(
          reservationId,
        )) as unknown as ReservationDetailedRead
        this.selectedReservation.is_anonymous = this.isReservationAnonymous(
          this.selectedReservation,
        )
        this.setSelectedTableIds(this.selectedReservation.tables)
      } finally {
        this.loadingReservationState = RESERVATION_LOADING_STATE.NONE
      }
    },
    removeSelectedReservation(): void {
      this.selectedReservation = null
      this.setSelectedTableIds([])
    },
    setSelectedTableIds(tableIds: PerformanceTableId[]): void {
      this.selectedTableIds = tableIds.slice()
    },
    setLowOpacityTableIds(tableIds: PerformanceTableId[]): void {
      this.lowOpacityTableIds = tableIds.slice()
    },
    setFilledTableIds(tableIds: PerformanceTableId[]): void {
      this.filledTableIds = tableIds.slice()
    },
    toggleTableId(tableId: PerformanceTableId): void {
      const index = this.selectedTableIds.indexOf(tableId)
      if (index === -1) {
        this.selectedTableIds.push(tableId)
      } else {
        this.selectedTableIds.splice(index, 1)
      }
    },
    walkInTable(tableEvent: PerformanceTableEvent): void {
      const table = this.tableById(tableEvent.tableId)
      if (tableEvent.activeReservationId !== null && !table!.standing_room) return
      this.toggleTableId(tableEvent.tableId)
    },
    toggleReservationTable(tableEvent: PerformanceTableEvent): void {
      if (
        tableEvent.activeReservationId === null ||
        tableEvent.activeReservationId === this.selectedReservation?.id
      ) {
        this.toggleTableId(tableEvent.tableId)
      }
    },
    onGuestChange(guest: GuestRead) {
      if (!this.selectedPerformance) return
      this.selectedPerformance.reservations.forEach(reservation => {
        if (reservation.contact?.id === guest.id) {
          Object.assign(reservation.contact, guest)
        }
      })
    },
    tableById(tableId: PerformanceTableId): PerformanceTableRead | undefined {
      return _.find(this.selectedPerformance!.tables, { id: tableId })
    },
  },
  getters: {
    isLoadingSelectedPerformance(): boolean {
      return this.loadingState === PERFORMANCE_LOADING_STATE.LOADING
    },
    isLoadingSelectedReservation(): boolean {
      return this.loadingReservationState === RESERVATION_LOADING_STATE.LOADING
    },
    hasSelectedTables(): boolean {
      return this.selectedTables.length > 0
    },
    performanceReservations(): ReservationRead[] {
      if (!this.selectedPerformance) return []
      const userStore = useUserStore()
      return this.selectedPerformance.reservations.map(reservation => {
        const user = reservation.contact || userStore.userById(reservation.user)
        return { ...reservation, user, is_anonymous: this.isReservationAnonymous(reservation) }
      })
    },
    activeReservations(): ReservationRead[] {
      return _.reject(this.performanceReservations, reservation => {
        return (
          reservation.status === ReservationStatus.CANCELED ||
          reservation.status === ReservationStatus.CLEARED ||
          reservation.status === ReservationStatus.OBSOLETE
        )
      })
    },
    inactiveReservations(): ReservationRead[] {
      return _.filter(this.performanceReservations, reservation => {
        return (
          reservation.status === ReservationStatus.CANCELED ||
          reservation.status === ReservationStatus.CLEARED
        )
      })
    },
    dashboardInformation() {
      const numberOfGuests = sum(this.activeReservations.map(r => r.number_of_guests))
      const numberOfWalkIns = sum(
        this.activeReservations.filter(isWalkIn).map(r => r.number_of_guests),
      )
      const numberOfHeldTables =
        this.selectedPerformance?.tables.filter(table => table.held).length || 0
      return {
        reservations: this.activeReservations,
        numberOfGuests,
        numberOfWalkIns,
        numberOfHeldTables,
      }
    },
    tablesWithReservation(): PerformanceTableWithReservations[] {
      if (!this.selectedPerformance) return []
      return this.selectedPerformance.tables.map(table => {
        const activeReservation = _.find(this.activeReservations, reservation => {
          return _.includes(reservation.tables, table.id)
        })
        return {
          ...table,
          updateStatus: TABLE_UPDATE_STATUS.AVAILABLE,
          reservationStatus: activeReservation
            ? activeReservation.status
            : TABLE_UPDATE_STATUS.AVAILABLE,
          activeReservation: activeReservation || null,
          inactiveReservations: _.filter(this.inactiveReservations, reservation => {
            return _.includes(reservation.tables, table.id)
          }),
          hidden: false,
          allowToDelete: false,
          hasUpdates: false,
        }
      })
    },
    activeReservationTableIds(): PerformanceTableId[] {
      return this.tablesWithReservation
        .filter(table => Boolean(table.activeReservation))
        .map(table => table.id)
    },
    selectedTables(): PerformanceTableWithReservations[] {
      return this.tablesWithReservation.filter(t => this.selectedTableIds.includes(t.id))
    },
    selectedTablesName(): string {
      return this.selectedTables.map(table => table.name + (table.held ? ' (Held)' : '')).join(', ')
    },
  },
})
